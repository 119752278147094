<template>
  <div style="margin-left: 16px;margin-right: 15px;text-align: center;">
    <van-row type="flex" justify="space-between" style="margin-top: 0px;">
      <van-col span="8" class="info-title-left">开户银行编号</van-col>
      <van-col span="16" class="info-content-right">{{ acctBankCode }}</van-col>
      <van-col span="24" class="split-line"></van-col>
      <van-col span="8" class="info-title-left">开户银行名称</van-col>
      <van-col span="16" class="info-content-right">{{ acctBankName }}</van-col>
      <van-col span="24" class="split-line"></van-col>
      <van-col span="8" class="info-title-left">卡片类型</van-col>
      <van-col span="16" class="info-content-right">{{ acctCardType }}</van-col>
      <van-col span="24" class="split-line"></van-col>
      <van-col span="8" class="info-title-left">开户名</van-col>
      <van-col span="16" class="info-content-right">{{ acctName }}</van-col>
      <van-col span="24" class="split-line"></van-col>
      <van-col span="8" class="info-title-left">收款银行账号</van-col>
      <van-col span="16" class="info-content-right">{{ acctNo }}</van-col>
      <van-col span="24" class="split-line"></van-col>
      <van-col span="8" class="info-title-left">预留电话</van-col>
      <van-col span="16" class="info-content-right">{{ acctPhone }}</van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  name: "bank_info",
  data() {
    return {
      acctBankCode: "",
      acctBankName: "",
      acctCardType: "",
      acctName: "",
      acctNo: "",
      acctPhone: "",



    }
  },
  methods: {
    get_detail() {

      this.acctBankCode = this.$route.query.acctBankCode
      this.acctBankName = this.$route.query.acctBankName
      this.acctName = this.$route.query.acctName
      this.acctNo = this.$route.query.acctNo
      this.acctPhone = this.$route.query.acctPhone


      switch (this.$route.query.acctCardType){
        case "401":
          this.acctCardType = "借记卡";
          break;
        case "601":
          this.acctCardType = "对公账户";
          break;
        case "701":
          this.acctCardType = "二类户";
          break;
        case "702":
          this.acctCardType = "三类户";
          break;
        default:
          this.acctCardType = "";
      }




    },
  },
  mounted() {
    this.get_detail()
  },
  created() {
    document.title = "银行账户信息"
  },
}
</script>

<style scoped>
.info-title-left {
  text-align: left;
  color: #707070;
  margin-top: 18px;
}

.info-content-right {
  text-align: right;
  margin-top: 18px;
}

.split-line {
  height: 1px;
  margin-top: 16px;
  margin-bottom: 0px;
  background-color: #F6F6F6;
}
</style>